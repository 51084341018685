<template>
  <div class="pre-footer-cta has-text-centered">
    <h2 class="title is-1">Browse by product</h2>
    <p class="pb-5">
      Looking for a product? Find the best fit for yout needs!
    </p>
    <button class="button is-medium">Go to products</button>
  </div>
</template>

<script>
export default {
  name: 'PreFooterCtaServices',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pre-footer-cta{
  position: relative;
  width: 100%;
  height: 400px;
  margin-top: 50px;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../assets/images/2-service-pages-hero.jpg");
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2, p{
    color: #F6F4EF;
  }
  button {
    border-color: #F6F4EF;
    color: #F6F4EF;
    background-color: transparent;
    text-transform: uppercase;
  }
}
</style>
