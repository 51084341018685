<template>
  <div :id="'laboratory-' + laboratory.id" class="laboratory-card" v-bind:class="{'is-big-card': isBigCard}">
    <div class="laboratory-icon">
      <span class="icon-lab"></span>
    </div>
    <h4 class="laboratory-name" v-html="laboratory.name">
    </h4>
    <p class="laboratory-location">
      {{laboratory.address_city}}, {{laboratory.address_state}}
    </p>
    <p class="laboratory-ship-esitmate" v-html="laboratory.shippingEstimate">
    </p>
    <hr>
    <div class="rating-wrap">
      <span class="icon-star" v-bind:class="{'active': laboratory.rating >= 1}"></span>
      <span class="icon-star" v-bind:class="{'active': laboratory.rating >= 2}"></span>
      <span class="icon-star" v-bind:class="{'active': laboratory.rating >= 3}"></span>
      <span class="icon-star" v-bind:class="{'active': laboratory.rating >= 4}"></span>
      <span class="icon-star" v-bind:class="{'active': laboratory.rating >= 5}"></span>
    </div>
    <p class="rating-text"><span>{{(laboratory.reviews > 0) ? laboratory.reviews : 0}}</span> opiniones</p>
    <button v-if="!isBigCard" class="button is-medium is-primary" @click="openLabDetail()">EMPEZAR UN ORDEN</button>
    <button v-if="isBigCard" class="favourite-btn" v-bind:class="{'is-favourite': isFavourite}" @click="toggleFavourite()">
      <label v-if="!isFavourite">Guardar</label>
      <label v-if="isFavourite">Eliminar</label>
      <span class="icon-favoritos"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'LaboratoryCard',
  props: {
    laboratory: Object,
    isBigCard: Boolean
  },
  data () {
    return {
      isFavourite: false
    }
  },
  methods: {
    toggleFavourite () {
      this.isFavourite = !this.isFavourite
    },
    openLabDetail () {
      this.$router.push('/laboratories/details/' + this.laboratory.id)
    }
  },
  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.laboratory-card{
  position: relative;
  background-color: #FFF;
  width: calc(33.33333333333% - 15px);
  margin-left: 15px;
  margin-bottom: 70px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  text-align: center;
  transition: all .4s ease-out;
  padding: 20px;
  &:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  &.is-big-card{
    width: 100%;
    padding: 20px 35px;
    margin-left: 0px;
    margin-bottom: 20px;
    text-align: left;
    .laboratory-icon{
      position: relative;
      height: 100px;
      width: 100px;
      margin-top: -70px;
      transform: initial;
      top: 0px;
      left: 0px;
      span{
        font-size: 3rem;
      }
    }
    .laboratory-name{
      margin-top: 14px;
      font-size: 26px!important;
      line-height: 31px!important;
    }
    .laboratory-location{
      padding-top: 14px;
      font-size: 18px;
      font-weight: 400;
    }
    .rating-wrap{
      width: 100%;
      margin-top: 15px;
      display: flex;
      justify-content: flex-start;
      .icon-star{
        opacity: .1;
        font-size: 2rem;
        &.active{
          opacity: 1;
        }
      }
    }
    .laboratory-ship-esitmate{
      display: none;
    }
    .favourite-btn{
      position: absolute;
      bottom: 20px;
      right: 25px;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
      &.is-favourite{
        span{
          color: #E3914F;
        }
      }
      label{
        position: relative;
        top: -5px;
        text-decoration: underline;
        margin-right: 14px;
        cursor: pointer;
      }
      span{
        display: inline-block;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        font-size: 1.8rem;
        background-color: #F6F4EF;
      }
    }
  }
  .laboratory-icon{
    position: absolute;
    height: 76px;
    width: 76px;
    top: -38px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #FFF;
    border-radius: 50%;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.2rem;
      &:before{
        color: #57567C;
      }
    }
  }
  .laboratory-name{
    margin-top: 35px;
    text-transform: uppercase;
    color: #57567C;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }
  .laboratory-location{
    color: #57567C;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    padding-top: 10px;
  }
  .laboratory-ship-esitmate{
    text-transform: uppercase;
    color: #57567C;
    font-size: .8rem;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    padding-top: 10px;
    span{
      font-size: 1.2rem;
    }
  }
  hr{
    background-color: #57567C;
    height: 1px;
  }
  .rating-wrap{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    .icon-star{
      opacity: .1;
      font-size: 1.3rem;
      &.active{
        opacity: 1;
      }
    }
  }
  .rating-text{
    // font-size: .8rem;
    padding-top: 14px;
    padding-bottom: 8px;
    span{
      font-weight: 600;
    }
  }
  button{
    font-size: 1rem;
    &.is-outline{
      color: #57567C;
      &:hover{
        color: #FFFFFF;
      }
    }
  }
}
@media screen and (max-width: 1130px) {
  .laboratory-card{
    width: calc(50% - 15px);
  }
}
@media screen and (max-width: 530px) {
  .laboratory-card{
    width: 100%;
  }
}
</style>
