<template>
  <div class="laboratories">
    <section class="container">
      <div class="row pt-6 pb-6">
        <div class="column is-6 is-offset-3">
          <h3 class="title is-3 has-text-centered">Buscar por producto</h3>
          <div class="control has-icons-right">
            <input class="input is-rounded" type="text" placeholder="Buscar un producto">
            <span class="icon is-right has-text-centered">
              <span class="icon-search"></span>
            </span>
            <p class="pt-3 has-text-centered">21 resultados encontrados</p>
          </div>
        </div>
      </div>
      <div class="row pt-6">
        <div class="columns">
          <div class="column is-4 filter-column">
            <div class="dropdown filter-ddl mobile" v-bind:class="{'is-active': isFilterDdlOpen }">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="toggleFilterDdl()">
                  <span class="ddl-label">FILTRAR POR</span>
                  <span class="icon is-small">
                    <span class="recommended-label is-small">Recommended</span>
                    <span class="icon-chevron-down" aria-hidden="true"></span>
                    <!-- <span class="icon-chevron-up" aria-hidden="false"></span> -->
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a href="#" class="dropdown-item">
                    Dropdown item
                  </a>
                  <a class="dropdown-item">
                    Other dropdown item
                  </a>
                  <a href="#" class="dropdown-item is-active">
                    Active dropdown item
                  </a>
                  <a href="#" class="dropdown-item">
                    Other dropdown item
                  </a>
                </div>
              </div>
            </div>
            <div class="dropdown sort-ddl" v-bind:class="{'is-active': isSortDdlOpen }">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="toggleSortDdl()">
                  <span class="ddl-label">ORDINAR POR</span>
                  <span class="icon is-small">
                    <span class="recommended-label is-small">Recommended</span>
                    <span class="icon-chevron-down" aria-hidden="true"></span>
                    <!-- <span class="icon-chevron-up" aria-hidden="false"></span> -->
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a href="#" class="dropdown-item">
                    Dropdown item
                  </a>
                  <a class="dropdown-item">
                    Other dropdown item
                  </a>
                  <a href="#" class="dropdown-item is-active">
                    Active dropdown item
                  </a>
                  <a href="#" class="dropdown-item">
                    Other dropdown item
                  </a>
                </div>
              </div>
            </div>
            <hr>
            <div class="filter-header">
              <p class="filter-title">FILTER BY</p>
              <p class="filter-result-recap"><span>7</span> results found</p>
              <button>Reset all filters</button>
            </div>
            <v-collapse-group :onlyOneActive="false" class="filter-wrap">
              <v-collapse-wrapper v-on:onStatusChange="checkCategoryFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">CATEGORY</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isCategoryFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isCategoryFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Implants
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Removable Prosthesis
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Fixed Prosthesis
                        </label>
                      </div>
                  </div>
              </v-collapse-wrapper>
              <v-collapse-wrapper v-on:onStatusChange="checkLocationFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">LOCATION</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isLocationFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isLocationFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Implants
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Removable Prosthesis
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Fixed Prosthesis
                        </label>
                      </div>
                  </div>
              </v-collapse-wrapper>
              <v-collapse-wrapper v-on:onStatusChange="checkServicesFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">SERVICES</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isServicesFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isServicesFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Implants
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Removable Prosthesis
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Fixed Prosthesis
                        </label>
                      </div>
                  </div>
              </v-collapse-wrapper>
              <v-collapse-wrapper v-on:onStatusChange="checkDeliveryFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">DELIVERY TIME</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isDeliveryFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isDeliveryFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Implants
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Removable Prosthesis
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Fixed Prosthesis
                        </label>
                      </div>
                  </div>
              </v-collapse-wrapper>
            </v-collapse-group>
          </div>
          <!-- SERVICES COLUMN -->
          <div class="column is-8 laboratories-column">
            <div class="laboratories-wrap">
              <template v-for="laboratory in laboratories">
                <LaboratoryCard :key="laboratory.id" :laboratory="laboratory" :is-big-card="false"></LaboratoryCard>
              </template>
            </div>
            <div class="columns">
              <div class="column is-6 is-offset-3">
                <button class="button is-medium is-fullwidth is-primary is-outline">VIEW MORE LABORATORIES</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PreFooterCtaServices></PreFooterCtaServices>
    <Footer></Footer>
  </div>
</template>

<script>
import PreFooterCtaServices from '@/components/PreFooterCtaServices.vue'
import LaboratoryCard from '@/components/LaboratoryCard.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Laboratories',
  components: {
    PreFooterCtaServices,
    LaboratoryCard,
    Footer
  },
  data () {
    return {
      isFilterDdlOpen: false,
      isSortDdlOpen: false,
      isCategoryFilterOpen: false,
      isServicesFilterOpen: false,
      isLocationFilterOpen: false,
      isDeliveryFilterOpen: false,
      // dummy laboratories
      laboratories: [
        {
          id: 1,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 2,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 3,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 4,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 5,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 6,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 7,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 8,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        },
        {
          id: 9,
          name: 'Laboratorio Dental STLAB Srl',
          address_city: 'Madrid',
          address_state: 'ES',
          shippingEstimate: 'Usually ships within 1-2 days',
          rating: 4,
          reviews: 44
        }
      ]
    }
  },
  methods: {
    checkServicesFilter (vm) {
      this.isServicesFilterOpen = vm.status
    },
    checkLocationFilter (vm) {
      this.isLocationFilterOpen = vm.status
    },
    checkDeliveryFilter (vm) {
      this.isDeliveryFilterOpen = vm.status
    },
    checkCategoryFilter (vm) {
      this.isCategoryFilterOpen = vm.status
    },
    toggleFilterDdl () {
      this.isFilterDdlOpen = !this.isFilterDdlOpen
    },
    toggleSortDdl () {
      this.isSortDdlOpen = !this.isSortDdlOpen
    }
  },
  created () {
    const self = this
    this.$store.dispatch('lab/getLabList', {
      cb: (response) => {
        self.laboratories = response
      },
      cbError: (error) => {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error.response.data.error
          }
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.laboratories{
  width: 100%;
  .icon{
    .icon-search{
      display: block;
      height: 34px;
      width: 34px;
      margin-right: 3px;
      margin-top: 3px;
      background-color: #E3914F;
      border-radius: 50%;
      line-height: 34px;
    }
  }
  .filter-column{
    .dropdown{
      width: 100%;
      max-width: 329px;
      &.mobile{
        display: none;
      }
      .dropdown-trigger{
        width: 100%;
        button{
          position: relative;
          justify-content: space-between;
          width: 100%;
          border: 1px solid #57567C;
          background-color: transparent;
          .icon{
            position: relative;
            top: -5px;
            width: auto;
            margin-right: 0px;
            font-size: .8rem;
            .ddl-label{
              font-size: 1.2rem;
              position: relative;
              top: 4px;
            }
            .recommended-label{
              position: relative;
              top: 6px;
            }
            .icon-chevron-down, .icon-chevron-up{
              position: relative;
              top: 6px;
              right: -5px;
            }
          }
        }
      }
    }
    .dropdown-menu{
      width: 100%;
      max-width: 329px;
    }
    hr{
      width: 100%;
      max-width: 329px;
      background-color: #57567C;
    }
    .filter-header{
      position: relative;
      width: 100%;
      max-width: 329px;
      margin-bottom: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid rgba(87, 86, 124, .2);
      .filter-title{
        color: #57567C;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 23px;
      }
      .filter-result-recap{
        position: absolute;
        top: 0px;
        right: 0px;
        color: #57567C;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
        span{
          font-weight: 600;
        }
      }
      button{
        border: 0px;
        padding: 0px;
        background-color: transparent;
        text-decoration: underline;
      }
    }
    .filter-wrap{
      width: 100%;
      max-width: 329px;
      .vc-collapse{
        margin-bottom: 17px;
        padding-bottom: 17px;
        border-bottom: 1px solid rgba(87, 86, 124, .2);
        .header{
          width: 100%;
          position: relative;
          .filter-title{
            color: #57567C;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
          }
          .filter-icon{
            position: absolute;
            top: 0px;
            right: 0px;
            color: #57567C;
            letter-spacing: 0;
            line-height: 23px;
            font-size: 1.3rem;
          }
        }
        .v-collapse-content{
          .filter-check{
            margin-top: 10px;
            label{
              color: #57567C;
              font-size: 14px;
              letter-spacing: 0;
            }
            input{
              position: relative;
              top: 5px;
              margin-right: 10px;
              height: 21px;
              width: 21px;
              background-color: transparent;
              color: #57567C;
              border: 1px solid #57567C;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
  .laboratories-column {
    .laboratories-wrap{
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 1024px) {
  .laboratories{
    .filter-column{
      padding-left: 28px;
    }
    .laboratories-column{
      padding-right: 28px;
    }
  }
}

@media screen and (max-width: 768px) {
  .laboratories{
    .container{
      .row{
        &.pb-6{
          padding-bottom: 0px!important;
        }
      }
    }
    .filter-column{
      padding-right: 28px;
      margin-bottom: 42px;
      .dropdown{
        width: 48%;
        .recommended-label{
          display: none;
        }
        &.filter-ddl{}
        &.sort-ddl{
          float: right;
        }
        &.mobile{
          display: inline-block;
        }
      }
      hr{
        display: none;
      }
      .filter-header {
        display: none;
      }
      .filter-wrap {
        display: none;
      }
    }
    .laboratories-column{
      .all-btn-wrap{
        padding-left: 25px;
      }
    }
  }
}
</style>
